<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar class="pt-1" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <v-text-field
            dense
            outlined
            label="Search Companies & Contacts"
            prepend-inner-icon="mdi-magnify"
            class="mr-8 mt-5"
            ref="searchBox"
            v-model="searchText"
          ></v-text-field>
          <slot name="searchDrawerCloseButton"></slot>
        </div>
      </v-app-bar>
    </div>

    <div class="px-5 mt-10">
      <div>
        <div class="heading-label pa-0 mb-4">
          Companies<span v-if="companiesFoundCount > 0"
            >({{ companiesFoundCount }})</span
          >
        </div>
        <div
          class="d-flex align-center mb-4"
          v-for="(company, companyIndex) in companiesFound"
          :key="company.id"
        >
          <v-avatar size="44" class="mr-2">
            <v-icon> mdi-office-building-outline </v-icon>
          </v-avatar>
          <div>
            <p class="ma-0">
              <router-link
                class="link-alt"
                :to="{
                  name: 'Company Dashboard',
                  params: { companyId: company.id },
                }"
                >{{ company.name }}</router-link
              >
            </p>
            <p class="text--disabled caption ma-0">{{ company.supportText }}</p>
          </div>
        </div>
        <div class="heading-label pa-0 mt-10 mb-4">
          Contacts<span v-if="contactsFoundCount > 0"
            >({{ contactsFoundCount }})</span
          >
        </div>
        <div
          class="d-flex align-center mb-4"
          v-for="(contact, contactIndex) in contactsFound"
          :key="contact.id"
        >
          <v-avatar size="44" class="mr-2">
            <v-icon> mdi-account-circle </v-icon>
          </v-avatar>
          <div>
            <p class="ma-0">
              <router-link class="link-alt" :to="{ name: 'Contact View', params: {contactId: contact.id} }">{{
                (contact.firstName ? contact.firstName : "") +
                " " +
                (contact.lastName ? contact.lastName : "")
              }}</router-link>
            </p>
            <p class="text--disabled caption ma-0">{{ contact.jobTitle }}</p>
          </div>
        </div>
        <!-- <div class="d-flex align-center mb-4">
          <img
            height="40"
            width="40"
            class="w-12 h-auto"
            src="@/assets/images/file-types/001-pdf.svg"
            alt=""
          />
          <div>
            <p class="ma-0">
              <a class="link-alt" href="#">Project_alpha_report.pdf</a>
            </p>
            <p class="text--disabled caption ma-0 ">24 playlist</p>
          </div>
        </div> -->
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
export default {
  watch: {
    searchText() {
      clearTimeout(this.debounceId);
      this.debounceId = setTimeout(() => {
        this.getData();
      }, 300);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    focusSearchBox() {
      this.$nextTick(() => {
        this.$refs.searchBox.$refs.input.click();
      });
    },
    getData() {
      this.$courier.Companies.getData(1, 4, this.searchText).then(
        (foundCompanies) => {
          this.companiesFound = foundCompanies.payload;
          this.companiesFoundCount = foundCompanies.totalCount;
        }
      );

      this.$courier.Contacts.getData(1, 4, this.searchText).then(
        (foundContacts) => {
          this.contactsFound = foundContacts.payload;
          this.contactsFoundCount = foundContacts.totalCount;
        }
      );
    },
  },
  data: () => ({
    debounceId: "",
    searchText: "",
    contactsFound: [],
    companiesFound: [],
    contactsFoundCount: 0,
    companiesFoundCount: 0,
  }),
};
</script>

<style></style>
